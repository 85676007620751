@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-gradion {
  background: linear-gradient(266.37deg, #2d7ad8 -2.59%, #00eeff 106%);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.bg-gradion:hover {
  background: linear-gradient(266.37deg, #00eeff -2.59%, #00eeff 106%);
}
.header {
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}
.header.scroll {
  background-color: white;
  -webkit-box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
}

.header.scroll .header-text-color {
  color: black;
}

.header.scroll .header-text-color:hover {
  color: #00b4ff;
}

.header.scroll .header-icon {
  color: #7ebec5;
}
.bgi-gradion {
  background-image: linear-gradient(270deg,#03b9f5 0%,#0f6cba 100%);
}
.bgi-gradion:hover {
  background: #00eeff ;
}

.swiper-pagination{
  position: static !important;
  margin-top: 40px;
}
